@import "utils/variables";
@import "utils/helper";
@import "base/default";
@import "base/typography";
@media only screen and (max-width: 1540px) {
    button.splide__arrow {
        &.splide__arrow {
            &--prev {
               left: 0;
            }
            &--next {
                right: 0;
            }
        }
    }
}
@media only screen and (max-width: 1490px) {
}
@media only screen and (max-width: #{$maxBreakpoint}) {
    .catalog-product-view{
        .main{
            .product{
                &-info {
                    &-main{
                        width: calc(100% - 50rem);
                    }
                    &-media{
                        max-width: 45rem;
                    }
                }
                &-add{
                    &-form{
                        padding-right: 0;
                        .swatch-attribute-options {
                            align-items: flex-start;
                            .swatch-option {
                                &.image {

                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-summary{
        #block-discount{
            margin-left: 0;
            width: 100%;
        }
    }
    .checkout-container {
        .opc {
            &-progress-bar, &-wrapper {
                width: calc(100% - 41.8rem);
                max-width: 100%;
            }
            &-wrapper{
                ol#checkoutSteps {
                    .shipping {
                        &-address {
                            &-item {
                                width: 49%;
                                &:nth-of-type(2n){
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-container {
        form {
            width: 100%;
            &.form-cart {
                width: calc(100% - 40rem);
                table{
                    display: flex;
                    flex-wrap: wrap;
                    thead{
                        width: 100%;
                    }
                    tr{
                        @include flex;
                        flex-wrap: wrap;
                        th{
                            display: none;

                        }
                        td{
                            @include flex;
                            flex-wrap: wrap;
                            text-align: left;
                            &.col[data-th]{
                                &:before{
                                    content: attr(data-th) ":";
                                    width: 100%;
                                    border-bottom: 0.1rem solid rgba($colorBlack, 0.5);
                                    padding: 0 2rem 1rem 0rem;
                                    padding-right: 0;
                                    text-align: left;
                                    font-size: 1.2rem;
                                    line-height: 1;
                                    letter-spacing: 0.18rem;
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    display: inline-block;
                                }
                            }
                            &.col {
                                padding-right: 4.5rem;
                                width: calc(39.5% - 4.5rem);
                                text-align: left;
                                padding-top: 0;
                                padding-bottom: 3rem;
                                > *{
                                    margin-top: 1rem;
                                    display: inline-block;
                                    width: 100%;
                                }
                                &.subtotal{
                                    padding-right: 0;
                                }
                                &.item {
                                    width: 100%;
                                    padding-right: 0;
                                    > a {
                                        width: 7.2rem;
                                        display: inline-block;
                                    }

                                    .product {
                                        &-item {
                                            &-details {
                                                display: inline-block;
                                                width: calc(100% - 8rem);
                                            }
                                        }
                                    }
                                }
                            }

                        }

                    }
                }

            }
        }
    }
    .checkout-index-index {
        .messages {
            .message {
                &.error {
                    max-width: calc(100% - 41.8rem);
                }
            }
        }
    }
}
@media only screen and (max-width: #{$maxBreakpoint}) and (min-width: #{$mediumBreakpoint}) {

    footer{
        .inner {
            &-main {
                &-container {
                    &.top{
                        .section{
                            .newsletter{
                                .form{

                                    flex-wrap: wrap;
                                    > div{
                                        width: 100%;
                                    }
                                    button{
                                        margin-right: 0;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .page-products {
        .page-wrapper {

            .category {
                &-view {
                }
                &-text{
                }
            }
            .category-image + .category-text{
                padding-right: 3.5rem;
            }

        }
    }
}
@media only screen and (max-width: 1280px) {
    .account{
        .page-wrapper .columns {
            justify-content: flex-end;
            .products-grid ol, widget.elementor-widget.product-grid ol {
                justify-content: flex-start;
            }
        }
    }

    footer{
        .footer {
            &-bottom {
                &-container {
                    flex-wrap: wrap;
                }
            }
        }
        .inner {
            &-main {
                &-container {
                    &.bottom {
                        justify-content: space-between;
                    }
                }
            }
        }
    }
    .checkout-index-index {
        .messages {
            .message {
                &.error {
                    max-width: calc(100% - 41.8rem);
                }
            }
        }
    }

}
@media only screen and (max-width: #{$mediumBreakpoint}) {

    .splide__arrows{
        display: none;
    }
    .modal-popup {
        .modal {
            &-header {
                margin-bottom: 2rem;
            }
            &-footer{
                margin-top: 2rem;
                .action{
                    line-height: normal;
                }
            }
        }
    }
    .webcrossing-popup-1{
        .modal{
            &-content{
                .newsletterPopup{
                    .image{
                        overflow: hidden;
                        img{
                            min-height: 100%;
                            max-width: inherit;
                            min-width: 100%;
                        }
                    }
                }
            }
        }
    }
    .catalog-product-view {
        .modal {
            &-header{

            }
            &-popup {
                &._show {
                    .modal-inner-wrap {
                        max-width: 90%;
                        padding: 2rem;
                    }
                }
            }
        }
    }
    .page-products {
        .sidebar{
            width: 100%;
            display: none;
        }
        .page-wrapper {

            .category {
                &-view{
                    &.hasImage{
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }
            .category-image + .category-text{
                padding-right: 3.5rem;
            }

        }
    }
    .checkout-index-index {
        .messages {
            .message {
                &.error {
                    max-width: 100%;
                }
            }
        }
    }
    .cart {
        &-container {
            form.form-cart{
                width: 100%;
                order: 0;
            }
        }
        &-summary{
            width: 100%;
            order: 1;
            margin-top: 7rem;
        }
    }
    .page-wrapper{
        background: $colorWhite;

    }
    .opc{
        &-progress, &-summary{
            &-bar, &-wrapper{
                width: 100%;
                padding: 0;
            }
        }
        &-wrapper{
            width: 100%;
            #checkout-shipping-method-load {
                padding-top: 0rem;
                padding-bottom: 0rem;
            }

            div#shipping-method-buttons-container .primary, div#checkout-payment-method-container .primary {
                margin: 0 auto !important;
                margin-bottom: 2.5rem !important;
            }

            .field.addresses, .new-address-popup {
                text-align: left;

                button {
                    min-width: 25rem;
                }
            }

            .new-address-popup {
                text-align: center;
            }

            .step-title {
                margin-bottom: 1rem !important;
            }

            #payment .payment-method:last-child {
                padding-bottom: 0;
            }
        }
    }
    .mm-panels{
        > .mm-panel{
            background: $colorWhite;
            -webkit-box-shadow: inset -11px 0px 15px -10px rgba(0,0,0,0.32);
            -moz-box-shadow: inset -11px 0px 15px -10px rgba(0,0,0,0.32);
            box-shadow: inset -11px 0px 15px -10px rgba(0,0,0,0.32);
            > .mm-listview:first-child, > .mm-navbar + .mm-listview{
                margin-top: 1.9rem;
            }

            .mm-navbar{
                display: block;
            }
        }
    }
    .login-container {
        flex-wrap: wrap;
        .block {
            width: 100%;
        }
    }
    .login-container {
        .block {
            .block-content {
                .actions-toolbar {
                    .primary {
                        button {
                            border: 1px solid $colorBlack;
                        }
                    }

                    > div {
                        margin-right: 1rem;
                    }
                }
            }
        }

    }
    .account {
        .columns {
            .sidebar-main {
                max-width: 25rem;
                margin-right: 20px;
                .account-nav{

                }
            }
            .column {
                &.main {
                    form {
                        &.form-address-edit {
                            fieldset {
                                width: 100%;
                            }
                        }
                    }
                    table{
                        width: 100%;
                        thead{
                            display: none;
                        }
                        tr{
                            display: block;
                            padding-bottom: 2rem ;
                            th{
                                display: none;
                            }
                            td{
                                display: block;
                                padding: 5px 10px;
                                a{
                                    padding: 0;
                                }
                                &:before{
                                    font-weight: 700;
                                    content:attr(data-th) ': ';
                                }
                                &:last-child{
                                    border-bottom: 1px solid $colorTertiary;
                                    padding-bottom: 2rem;
                                }
                            }
                        }
                    }
                }

            }
        }
    }
    .catalog-product-view{
        .main{
            .product{
                &-info {
                    &-detailed{
                        .items{
                            .item{
                                &.title{
                                    width: 100%;
                                    float: none;
                                    display: inline-block;
                                    border-top: 1px solid rgba($colorBlack, 0.3);
                                    padding: 0;
                                    padding-top: 1.8rem;
                                    padding-bottom: 1.8rem;
                                    a{
                                        min-height: 0;
                                        position: relative;
                                        padding: 0;
                                        &:before, &:after{
                                            background: rgba($colorBlack, 0.3) ;
                                            content: "";
                                            position: absolute;
                                            display: inline-block;
                                        }
                                        &:before{
                                            height: 1.725rem;
                                            width: .2rem;
                                            top: 0;
                                            right: 0.8rem;

                                        }
                                        &:after{
                                            height: .2rem;
                                            width: 1.725rem;
                                            right: 0;
                                            top: 0.8rem;
                                        }
                                    }
                                    &.active{
                                        a {
                                            padding: 0;
                                            &:before {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &.content{
                                    margin: 0;
                                    float: none;
                                    display: inline-block;
                                    padding-bottom: 1.8rem;
                                }
                            }
                        }
                    }
                    &-media{
                        max-width: 100%;
                        margin: 0;
                    }
                    &-main {
                        padding-left: 0;
                        width: 100%;
                        .page{
                            &-title{
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                                text-align: left;
                            }
                        }
                    }
                    &-details{
                        > div {
                            padding-right: 1rem;
                            padding-bottom: 0.4rem;
                        }
                    }

                }
                &-add{
                    &-form{
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .page {
        &-layout-2columns-left .products.list {
            .product-item {
                &:nth-of-type(3n) {
                    margin-right: 10px;
                }
            }
        }
        &-products .page-wrapper .columns .main {
            .products {
                &.list {
                    .product-item {
                        width: calc(50% - 5px);

                        &:nth-of-type(2n) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .webcrossing-widget-teaser-store {
        .block {
            &-content {
                flex-wrap: wrap;
                .image{
                    width: 100%;
                }
                .info{
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                    padding-bottom: 0;
                    line-height: 2.7rem;
                    h2{
                        font-size: 2.2rem;
                        padding-bottom: 2.3rem;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .page {
        &-header {
            .header {
                &-top {
                    padding: 0;
                    &-info{
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                        span{
                            font-size: 1.2rem;
                        }
                    }
                    &-account{
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                    }
                    &-contact{
                        border-color: rgba($colorWhite, 0.1);
                        padding-right: 1rem;
                        margin-right: 1rem;
                        ul{
                            li{
                                padding-top: 1.5rem;
                                padding-bottom: 1.5rem;
                                border-left:1px solid rgba($colorWhite, 0.1);
                                margin-left: 1rem;
                                padding-left: 1rem;
                                a{
                                    i{
                                        margin-left: 0;
                                        &.fal.fa-envelope, &.fab.fa-whatsapp {
                                            margin-left: 0;
                                        }
                                        &.fab.fa-facebook-square{
                                            margin-left: 0;
                                        }
                                    }
                                }

                            }
                        }
                    }
                    &-navigation {
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                        border-left:1px solid rgba($colorWhite, 0.1);
                        margin-left: 1rem;
                        padding-left: 2rem;
                        &.mobile {
                            display: block;

                            button {
                                display: inline-block;
                                border: none;
                                padding: 0;
                                cursor: pointer;
                                background: transparent;
                                width: 2.269rem;
                                line-height: 0;

                                &:before, &:after, span {
                                    height: .2rem;
                                    display: block;
                                    background: $colorWhite;
                                }

                                &:before, &:after {
                                    content: "";
                                    width: 50%;
                                }

                                span {
                                    width: 100%;
                                    margin-top: .5rem;
                                }

                                &:after {
                                    margin-left: 50%;
                                    margin-top: .5rem;

                                }
                            }
                        }
                    }
                    &-container{
                        .inner{
                            &-top{
                                &-container{
                                    &.right{
                                        padding: 0;
                                    }
                                    &.left{
                                        max-width: 31rem;
                                        img{
                                            &.maloja{
                                                max-width: 8.3rem;

                                            }

                                        }
                                        .mobile{
                                            display: none;
                                        }
                                        .desktop{
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
                &-main{
                    padding: 1rem 0;
                    background: $colorWhite;
                    &-cart{
                        a{
                            span{
                                &.counter-number{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                            }
                        }
                    }
                    &-search{
                        .block-search {
                            .block{
                                &-content{
                                   top: 5rem;
                                }
                            }
                            &.active {
                                .block-content {
                                    top: 14.7rem;
                                    .actions {
                                        button {
                                            line-height: auto;
                                            span{
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }/* End Header */
        &-wrapper{
            .columns .main {
                .page-title-wrapper {
                    .page-title {
                        text-align: left;
                    }
                }
                .toolbar {
                    margin: 0;
                    margin-bottom: 3rem;
                }
            }
        }/* End .page-wrapper */
    }
    footer{
        .footer {
            &-main {
                &-container {
                    padding-top: 2.2rem;
                }
            }
            &-bottom{
                &-container{
                    justify-content: center;
                    text-align: center;
                    padding-top: 1.9rem;
                    padding-bottom: 2.4rem;
                }
            }
            &-top{
                &-info{
                    ul{
                        li{

                            .icon{
                                width: 5rem;
                            }
                        }
                    }
                }
            }
        }
        .inner {
            &-main {
                &-container {
                    &.top {
                        flex-wrap: wrap;
                        padding-bottom: 0;
                        margin-bottom: 0rem;
                        border-bottom: 1px solid rgba($colorBlack, 0.3);
                        .section {
                            width: 100%;

                            &-title {
                                padding-top: 2rem;
                                padding-bottom: 2rem;
                                border-top: 1px solid rgba($colorBlack, 0.3);

                                h4 {
                                    padding-bottom: 0;
                                    position: relative;
                                    &:before, &:after{
                                        background: rgba($colorBlack, 1) ;
                                        content: "";
                                        position: absolute;
                                        display: inline-block;
                                    }
                                    &:before{
                                        height: 1.725rem;
                                        width: .2rem;
                                        top: 0;
                                        right: 0.8rem;

                                    }
                                    &:after{
                                        height: .2rem;
                                        width: 1.725rem;
                                        right: 0;
                                        top: 0.8rem;
                                    }
                                }
                                &.active{
                                    h4{
                                        &:before{
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &-content {
                                margin-bottom: 2rem;
                                @include defaultTrans;

                                ul {
                                    flex-wrap:wrap;
                                    gap:1rem 0;
                                }

                                p{
                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                                &.active{
                                    height: auto;
                                    margin-bottom: 2.7rem;
                                }

                            }
                            &.first{
                                order: 2;
                            }
                            &.second{
                                order: 3;
                            }
                            &.third {
                                margin-left: 0;
                                order: 1;
                            }
                            &.fourth{
                                order: 4;
                                ul{
                                    max-width: 100%;
                                    li{
                                        margin-right: 1%;
                                        width: 24%;
                                        margin-bottom: 0;
                                        &:nth-of-type(4n){
                                            margin-right: 0;
                                        }
                                    }
                                }
                                .section {
                                    &-title{
                                        h4{
                                            &:before, &:after{
                                                display: none;
                                            }
                                        }
                                    }
                                    &-content {
                                        height: auto;
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }

                    &.bottom {
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .section {
                            width: 100%;
                            padding-bottom: 0;
                            border-bottom: 0px solid rgba($colorBlack, 0.3);

                            &-content {
                                ul {

                                    li {
                                        margin-right: 1.5rem;
                                        padding-bottom: 1.5rem;
                                    }
                                }
                            }

                            &.third {
                                border-bottom: 0;
                                text-align: center;
                                width: 100%;
                                padding: 2rem 0;
                                > *{
                                    display: inline-block;
                                    width: auto;
                                    vertical-align: middle;
                                }
                                h4{
                                    padding: 0;
                                }
                                ul{
                                    li{
                                       padding-bottom: 0;
                                        span{
                                            display: inline-block;
                                            line-height: normal;
                                            margin-top: .8rem;
                                            img{

                                            }
                                        }
                                    }
                                }
                            }

                            h4 {
                                padding-top: 2rem;
                            }
                        }
                    }
                }
            }
            &-bottom{
                &-container{
                    width: 100%;
                    &.middle{
                        order: 0;
                    }
                    &.left{
                        padding-top: 1.3rem;
                        padding-bottom: 1.3rem;
                        order: 1;
                        .section{
                            &-content{
                                ul {
                                    justify-content: center;
                                    text-align: center;
                                }
                            }
                        }
                    }
                    &.right{
                        order: 2;
                    }
                }
            }
        }
    }
    .checkout-container {
        .opc {
            &-summary-wrapper{
                margin-top: 1rem;
                margin-bottom: 8rem;
            }
            &-progress-bar, &-wrapper, &-summary-wrapper {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 940px){
    .page-products .page-wrapper {
        .category {
            &-view {
                flex-wrap: wrap;
                max-width: $smallContent;
            }
            &-image{
                width: 100%;
            }
            &-text{
                width: 100%;
                padding: 3rem 0;

            }

        }
        .category-image + .category-text{
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    }

}
@media only screen and (max-width: #{$mediumSmallBreakpoint}) {
    .page-products .page-wrapper {
        .category {
            &-view {
                max-width: 100%;
            }

        }
    }
    table{
        width: 100% !important;
        border:0;
        tr{
            display: block;
            padding-bottom: 1rem ;
            th{
                display: none;
            }
            td {
                display: block;
                padding: .5rem 0;
                border:0;

                &:before{
                    font-weight: 700;
                }
                &[data-th]{
                    &:before{
                        content: attr(data-th) ": ";
                    }
                }
                &:last-child{
                    border-bottom: 1px solid $colorPrimary;
                    padding-bottom: 2rem;
                }
            }

        }
        thead + tbody{
            tr{
                td{

                }
            }

        }
    }
    .modal-popup {
        .modal {
            &-title{
                padding-right: 5rem;
            }
            &-content {
                @include flexWrap;
                padding: 0 3rem;
                .product-info {
                    &-media {
                        width: 100%;
                    }
                    &-main {
                        width: 100%;
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .webcrossing-popup-1{

        .modal{
            &-content{
                .newsletterPopup{
                    .image{
                        display: none;
                    }
                    .text{
                        width: 100%;
                        &Container{
                            .inputfield{
                                .newsletter{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            &-inner-wrap{
                width: calc(100% - 3.2rem);
                max-width: 48rem;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }
    .page-products .page-wrapper .main {
        .category {
            &-view {

                max-width: 100%;
            }


        }
    }
    .checkout-container {
        .opc {
            &-wrapper{
                ol#checkoutSteps {
                    .shipping {
                        &-address {
                            &-item {
                                width: 100%;
                                margin-right: 0;
                                .action{
                                    width: 49%;
                                    margin-right: 1%;
                                    line-height: normal;
                                    &-select-shipping-item{
                                        margin-right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        div#checkout-shipping-method-load {
            tr{
                border-bottom: 1px solid $colorPrimary;
                @include flex;
                padding-bottom: 0;
            }
            td.col {
                display: inline-block;
                &.col-method:last-child {
                    border: 0;

                }
            }
        }
    }


    .block {
        &-title {
            h2{
                font-size: 2.2rem;
                line-height: 3.2rem;
                padding-bottom: 1.5rem;
            }
        }
    }
    .splide__arrows{
        display: none;
    }
    .account {
        .page-wrapper {
            .products-grid ol, widget.elementor-widget.product-grid ol {
                justify-content: center;
            }
            .page-main {
                .page-title-wrapper{
                    padding-top: 0;
                }
                .columns {
                    flex-wrap: wrap;
                }
            }
        }
        .columns {
            .sidebar-main {
                max-width: 100%;
                margin-right: 0;
                margin-top: 2rem;
                a, strong{
                    display: inline-block;
                    padding: 1rem 0;
                }
                .account-nav-title{
                    display: none;
                }
            }
            .column {
                &.main {
                    form {
                        &.form-address-edit {
                            fieldset {
                                width: 100%;
                            }
                        }
                    }
                    .table-wrapper {
                        table {
                            @include flexWrap;
                            thead{
                                width: 100%;
                                @include flex;
                                tr{
                                    width: 100%;
                                    th{
                                        width: 100%;
                                        display: none;
                                        &.item{
                                            display: inline-block;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    justify-content: center;
                                    @include flexWrap;
                                    width: 100%;
                                    td.col {
                                        width: 100%;
                                        text-align: left;
                                        padding: 0 0rem 1.5rem;
                                        &[data-th]{
                                            &:before{
                                                padding-bottom: 3px;
                                                content: attr(data-th) ": ";
                                                display: inline-block;
                                                font-weight: 700;

                                            }
                                        }
                                        a{
                                            display: inline-block;
                                            padding: 0;
                                            @include fontDefault;
                                            letter-spacing: normal;
                                        }
                                        &.item {
                                            &[data-th]{
                                                &:before{
                                                    content: "";
                                                }
                                            }
                                            width: 100%;
                                            @include flexCenter;
                                            @include flexWrap;
                                            position: relative;
                                            padding-bottom: 0;
                                            a{
                                                display: inline-block;
                                                width: 100%;
                                                text-align: center;
                                                span{
                                                    display: inline-block;
                                                    padding-bottom: 0 !important;
                                                }
                                                .product-image-container{
                                                    width: 100% !important;
                                                    text-align: center;
                                                    display: inline-block;
                                                }
                                            }
                                        }/* end Item */
                                        &.subtotal{
                                            background: $colorLightGrey;
                                            width: 100%;
                                            padding: 10px;
                                        }
                                    }
                                    &.item-actions{
                                        width: 100%;
                                        td{
                                            width: 100%;
                                            padding-right: 0;
                                            .actions-toolbar{
                                                a{
                                                    &.towishlist{
                                                        text-align: center;
                                                        margin: 0;
                                                        margin-bottom: 20px;
                                                    }
                                                    &.action-delete, &.action-edit{
                                                        width: auto;
                                                        padding:15px 20px !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }
    .catalog-product-view {
        .main {
            .product {
                &-info {
                    &-price{
                        margin-bottom: 2rem;
                        margin-top: 1rem;
                        padding-bottom: 0rem;
                    }
                    &-main {
                        width: 100%;
                        padding-left: 0rem;
                    }
                    &-details{
                        flex-wrap: wrap;
                    }
                    &-detailed{
                        .items {
                            .item {
                                &.title{
                                    a{
                                        font-size: 1.4rem;
                                    }
                                }
                                .content {
                                    .inner-content {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                }
                &-add{
                    &-form{
                        .swatch-attribute {
                            &.color{
                                padding-bottom: 2rem;
                            }
                            &-options {
                                .swatch-option {
                                    &.image {
                                        width: 15%;
                                        .label{
                                            margin-top: 0;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .webcrossing-widget-product-slider {
        .block-content {
            .items {
                .item {
                    &.title {
                        a {
                            padding-bottom: 0;
                        }
                    }
                    &.content{
                        margin-top: 3rem;
                    }
                }
            }
        }
    }
    .page {
        &-header {
            .header {
                &-main {
                    padding: 1.7rem 0;
                }
                &-top {
                    &-info {
                        font-size: 0.8rem;
                        span{
                            max-width: 7rem;
                            line-height: normal;
                            font-size: .8rem;
                        }


                    }

                    &-account {
                        a {
                            font-size: 1rem;
                        }
                    }
                    &-contact {
                        ul {
                            li {
                                &.social {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }/* END HEADER */
        &-wrapper {
            .breadcrumbs ul li > *{
                font-weight: 700;
            }
            .filter {
                &-options {
                    &-content {
                        .swatch {
                            &-attribute {
                                &-options {
                                    flex-wrap: wrap;
                                    a{
                                        margin-bottom: 1rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .products {
        &.list {
            border-color: rgba($colorBlack, 0.1);
            .product {
                &-item {
                    &-color {
                        position: relative;
                        bottom: inherit;
                        left: inherit;
                    }
                    &-overlay{
                        .swatch{
                            &-more{
                                display: none;
                            }
                            &-attribute{
                                margin-bottom: 0;
                                &-options{
                                    padding-top: 1rem;
                                    >a{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .webcrossing-widget-image-slider {
        .block-content {
            .splide__arrows {
                button.splide__arrow.splide__arrow--next {
                    display: none;
                }
            }
            ul {
                li {
                    max-height: inherit;
                    overflow: auto;
                    background: $colorPrimary;
                    .info {
                        @include contentMedium;
                        position: relative;
                        margin: 0 auto;
                        top: 0;
                        left: 0;
                        transform: translate(0,0);
                        padding:4rem 0rem;
                        color: $colorWhite;
                        &.white{

                            h2{

                            }
                        }
                        h2{
                            color: $colorWhite;
                            font-size: 3.5rem;
                        }
                        p{
                            font-size: 1.2rem;
                        }
                        .actions{
                            a{
                                margin-right: 1rem;
                                line-height: normal;
                            }
                        }
                    }
                }
            }
        }
    }
    .cart{
        &-container{
            form {
                .actions {
                    flex-wrap: wrap;
                    > .action{
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 1rem;
                        line-height: normal;
                    }
                }
                &.form-cart{
                    table{
                        tr{
                            td{
                                &.col{
                                    width: 100%;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    footer{
        .footer{
            &-top{
                &-info{
                    ul{
                        li{
                            font-size: 1.1rem;
                            .icon{
                                width: 5rem;
                            }
                        }
                    }
                }
                &-newsletter{
                    @include contentMedium;
                    .section-content{
                        .newsletter{
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            .title{
                                width: 100%;
                                margin-bottom: 2rem;
                            }
                        }
                    }
                }
            }
        }
        .inner{
            &-main{
                &-container{
                    &.top{
                        .section{
                            &.fourth{
                                ul{
                                    li{
                                        margin-bottom: 1.5rem;
                                        img{
                                            max-width: 15rem;
                                            width: 100%;
                                        }
                                        &:last-child{
                                            img{
                                                max-width: 5rem;
                                                width: 70%;
                                            }
                                        }
                                    }
                                }
                            }
                            .newsletter{
                                button{
                                    line-height: normal;
                                    font-size: 1.4rem;
                                    padding-top: 1.4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width:  #{$smallBreakpoint}) {
    .page-products .page-wrapper .main .category-text{
        padding: 3rem;
    }
    footer .footer-top-newsletter {
        .content{
            width: 100%;
        }
        .section-content {
            .newsletter{
                min-width: calc(100% - 6.4rem);
            }
            input{
                width: 100%;
            }
            .control, label{
                width: 100%;
            }
            .form {
                @include flexWrap;
                width: 100%;
                justify-content: flex-start;

                .field-recaptcha {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 2rem;
                }
            }
        }
    }
}
@media only screen and (max-width:  #{$mobileBreakpoint}) {
    .page {
        &-header .header-top-container {
            .inner {
                &-top {
                    &-container {
                        &.left {

                            .mobile {
                                display: inline-block;
                            }

                            .desktop {
                                display: none;
                            }

                        }
                    }
                }
            }
        }
        &-wrapper .main .toolbar .pages ul {
            margin-bottom: 0;
        }
        &-header{
            .header {
                &-top {
                    &-info {
                        padding-top: 0;
                        padding-bottom: 0;
                        img{
                            margin-left: 0;
                        }
                        p{
                            span{
                                display: block;
                                max-width: 100%;
                                &:first-child{
                                    margin-bottom: .7rem;
                                }


                            }
                        }
                    }
                }
            }
        }
    }
    .products {
        &.list {
            .product {
                &-item {
                    width: 100%;
                    margin-right: 0;

                }
            }
        }
    }
    .catalog-product-view {
        .main {
            .product {
                &-info {
                    &-main {
                        padding-bottom: 4.4rem;
                        line-height: 2.4rem;
                        margin-top: 2rem;
                        .page-title{
                            font-size: 3rem;
                            line-height: 4rem;
                        }
                    }
                    &-details{
                        font-size: 1rem !important;
                        line-height: 1.5rem;
                        flex-wrap: wrap;
                        padding-bottom: 2rem;
                        > div {
                            padding-right: 0.5rem;
                        }
                        .product.attribute.sku {
                            width: 100%;
                            padding-bottom: 0.4rem;
                        }
                    }
                    &-detailed{

                    }
                    &-price{
                        .price-box.price-final_price {
                            font-size: 2.4rem;
                            line-height: 3.4rem;
                        }
                    }
                }
                &-add{
                    &-form{
                        .swatch-attribute-options {
                            .swatch {
                                &-option {
                                    &.image {
                                        img {
                                            height: auto !important;
                                        }
                                    }
                                }
                            }
                        }
                        .box-tocart {
                            padding-bottom: 3rem;
                            .fieldset {
                                flex-wrap: wrap;
                                .actions{
                                    margin-top: 2rem;
                                    margin-left: 0;
                                    width: 100%;
                                    button{
                                        width: 100%;
                                    }
                                }
                                .field.spice_amount {
                                    width: 100%;
                                }
                                .field.qty {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
            }
            @media only screen and (max-width: 360px) {
                .field.qty {
                    width: 40%;
                }
                .actions {
                    width: 60%;
                }
            }
        }
    }

    footer {
        .footer{

        }
        .inner{
            &-main {
                &-container {
                    &.top{
                        .section{
                            &-title{
                                padding-top: 1rem;
                                padding-bottom: 1rem;
                            }
                            &.fourth{
                                ul{
                                    li{


                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .columns {
        .main {
            .block{
                .splide__arrows{
                    display: none;
                }
            }
        }
    }

}

